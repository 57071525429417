export enum ESanityField {
  BREADCRUMB = 'breadcrumb',
  BREADCRUMBS = 'breadcrumbs',
  CURRENCY = 'currency',
  LANGUAGE = 'language',
  LINK = 'link',
  INTERNAL_LINK = 'internalLink',
  TITLE = 'title',
  TEXT = 'text',
  METADATA = 'metadata',
  METAL_ISO = 'metalIso',
  PERIOD = 'period',
  WEIGHT_UNIT = 'weightUnit',
  ALIGN = 'align',
  TIME_RANGE = 'timeRanges',
  SLUG = 'slug',
  API_PARAMS = 'apiParams',
  KEY_ROUTE = 'keyRoute',
  BODY = 'body',
  SIZE = 'size',
  TABS = 'tabs',
  HREF = 'href',
  VARIANT = 'variant',
  DESCRIPTION = 'description',
  SHORT_DESCRIPTION = 'shortDescription',
  AB_TEST = 'abTest',
  ALTERNATES = 'alternates',
  CONTROL = 'control',
  HISTORY = 'history',
  SUBCATEGORIES = 'subcategories',
  CAROUSEL_TITLE = 'carouselTitle',
  CAROUSEL_IMAGE = 'carouselImage',
  ID = 'id',
  HERO = 'hero',
  HEADER_VARIANT = 'headerVariant',
  HEADER_SETTINGS = 'headerSettings',
  PORTABLE_TEXT = 'portableText',
  VIDEO = 'video',
  FILE = 'file',
  IMAGE = 'image',
  EXTERNAL_IMAGE = 'externalImage',
}

export enum ESanityVariant {
  HOME = 'variantHome',
  CHARTS = 'variantCharts',
  PAGES = 'variantPages',
}
