import {
  ButtonColorProps,
  ButtonColorType,
  ButtonColorVariantHandler,
  EButtonVariant,
  EColor,
  ThemePalette,
} from '@core/type';

import { getPaletteHandlers } from './palette';

const getButtonContainedColorProps: ButtonColorVariantHandler = ({
  color,
  getColor,
  isDisabled,
}) => {
  if (isDisabled) {
    const neutral = getColor(EColor.NEUTRAL);

    return {
      color: getColor({ commonType: EColor.WHITE, intensity: EColor.R900 }),
      borderColor: neutral,
      backgroundColor: neutral,
    };
  }

  if (color === EColor.SECONDARY) {
    const whiteR900 = getColor({ commonType: EColor.WHITE, intensity: EColor.R900 });
    const secondary = getColor(EColor.SECONDARY);
    const secondaryDark = getColor({ semanticType: EColor.SECONDARY, variant: EColor.DARK });

    return {
      color: whiteR900,
      borderColor: secondary,
      backgroundColor: secondary,
      hover: {
        color: whiteR900,
        borderColor: secondaryDark,
        backgroundColor: secondaryDark,
      },
    };
  }

  if (color === EColor.ACCENT) {
    const whiteR900 = getColor({ commonType: EColor.WHITE, intensity: EColor.R900 });
    const secondaryDark = getColor({ semanticType: EColor.SECONDARY, variant: EColor.DARK });

    return {
      color: getColor({ semanticType: EColor.SECONDARY }),
      borderColor: whiteR900,
      backgroundColor: whiteR900,
      hover: {
        color: whiteR900,
        borderColor: secondaryDark,
        backgroundColor: secondaryDark,
      },
    };
  }

  if (color === EColor.INFO) {
    const grayA200 = getColor({ commonType: EColor.GRAY, intensity: EColor.A200 });

    return {
      color: getColor(EColor.PRIMARY),
      borderColor: grayA200,
      backgroundColor: grayA200,
      hover: {
        color: getColor({ semanticType: EColor.PRIMARY, variant: EColor.DARK }),
        borderColor: grayA200,
        backgroundColor: grayA200,
      },
    };
  }

  if (color === EColor.SUCCESS) {
    const success = getColor(EColor.SUCCESS);

    return {
      color: getColor({ commonType: EColor.WHITE, intensity: EColor.R900 }),
      borderColor: success,
      backgroundColor: success,
    };
  }

  if (color === EColor.WARNING) {
    const warning = getColor(EColor.WARNING);

    return {
      color: getColor({ commonType: EColor.WHITE, intensity: EColor.R900 }),
      borderColor: warning,
      backgroundColor: warning,
    };
  }

  if (color === EColor.DANGER) {
    const danger = getColor(EColor.DANGER);

    return {
      color: getColor({ commonType: EColor.WHITE, intensity: EColor.R900 }),
      borderColor: danger,
      backgroundColor: danger,
    };
  }

  if (color === EColor.YELLOW) {
    const yellowR600 = getColor({ commonType: EColor.YELLOW, intensity: EColor.R600 });

    return {
      color: getColor({ commonType: EColor.WHITE, intensity: EColor.R900 }),
      borderColor: yellowR600,
      backgroundColor: yellowR600,
    };
  }

  // EColor.PRIMARY as default
  const whiteR900 = getColor({ commonType: EColor.WHITE, intensity: EColor.R900 });
  const primary = getColor(EColor.PRIMARY);
  const primaryDark = getColor({ semanticType: EColor.PRIMARY, variant: EColor.DARK });

  return {
    color: whiteR900,
    borderColor: primary,
    backgroundColor: primary,
    hover: {
      color: whiteR900,
      borderColor: primaryDark,
      backgroundColor: primaryDark,
    },
  };
};

const getButtonOutlinedColorProps: ButtonColorVariantHandler<{ isTransparent: boolean }> = ({
  color,
  getColor,
  isDisabled,
  isTransparent,
}) => {
  const transparent = getColor(EColor.TRANSPARENT);

  if (isDisabled) {
    const neutral = getColor(EColor.NEUTRAL);

    return {
      color: neutral,
      borderColor: neutral,
      backgroundColor: transparent,
    };
  }

  const whiteR900 = getColor({ commonType: EColor.WHITE, intensity: EColor.R900 });

  if (color === EColor.SECONDARY) {
    const secondary = getColor(EColor.SECONDARY);
    const secondaryDark = getColor({ semanticType: EColor.SECONDARY, variant: EColor.DARK });

    return {
      color: secondary,
      borderColor: secondary,
      backgroundColor: isTransparent ? transparent : whiteR900,
      hover: {
        color: getColor({ commonType: EColor.WHITE, intensity: EColor.R900 }),
        borderColor: secondaryDark,
        backgroundColor: secondaryDark,
      },
    };
  }

  if (color === EColor.SUCCESS) {
    const success = getColor(EColor.SUCCESS);

    return {
      color: whiteR900,
      borderColor: success,
      backgroundColor: isTransparent ? transparent : whiteR900,
      hover: {
        color: whiteR900,
        borderColor: success,
        backgroundColor: success,
      },
    };
  }

  if (color === EColor.WARNING) {
    const warning = getColor(EColor.WARNING);

    return {
      color: warning,
      borderColor: warning,
      backgroundColor: isTransparent ? transparent : whiteR900,
      hover: {
        color: whiteR900,
        borderColor: warning,
        backgroundColor: warning,
      },
    };
  }

  if (color === EColor.DANGER) {
    const danger = getColor(EColor.DANGER);

    return {
      color: danger,
      borderColor: danger,
      backgroundColor: isTransparent ? transparent : whiteR900,
      hover: {
        color: whiteR900,
        borderColor: danger,
        backgroundColor: danger,
      },
    };
  }

  if (color === EColor.WHITE) {
    return {
      color: whiteR900,
      borderColor: whiteR900,
      backgroundColor: transparent,
      hover: {
        color: getColor({ semanticType: EColor.PRIMARY, variant: EColor.DARK }),
        borderColor: whiteR900,
        backgroundColor: whiteR900,
      },
    };
  }

  if (color === EColor.YELLOW) {
    const yellowR600 = getColor({ commonType: EColor.YELLOW, intensity: EColor.R600 });

    return {
      color: yellowR600,
      borderColor: yellowR600,
      backgroundColor: isTransparent ? transparent : yellowR600,
      hover: {
        color: whiteR900,
        borderColor: yellowR600,
        backgroundColor: yellowR600,
      },
    };
  }

  if (color === EColor.INFO) {
    const grayA200 = getColor({ commonType: EColor.GRAY, intensity: EColor.A200 });

    return {
      color: getColor(EColor.PRIMARY),
      borderColor: grayA200,
      backgroundColor: isTransparent ? transparent : whiteR900,
      hover: {
        color: getColor({ semanticType: EColor.PRIMARY, variant: EColor.DARK }),
        borderColor: grayA200,
        backgroundColor: grayA200,
      },
    };
  }

  const primary = getColor(EColor.PRIMARY);
  const primaryDark = getColor({ semanticType: EColor.PRIMARY, variant: EColor.DARK });

  return {
    color: primary,
    backgroundColor: isTransparent ? transparent : whiteR900,
    borderColor: primary,
    hover: {
      color: whiteR900,
      backgroundColor: primaryDark,
      borderColor: primaryDark,
    },
  };
};

const getButtonTextColorProps: ButtonColorVariantHandler = ({ color, getColor, isDisabled }) => {
  const transparent = getColor(EColor.TRANSPARENT);

  if (isDisabled) {
    const neutral = getColor(EColor.NEUTRAL);

    return {
      color: neutral,
      borderColor: transparent,
      backgroundColor: transparent,
    };
  }

  if (color === EColor.WHITE) {
    const whiteR900 = getColor({ commonType: EColor.WHITE, intensity: EColor.R900 });
    const blueA700 = getColor({ commonType: EColor.BLUE, intensity: EColor.A700 });

    return {
      color: whiteR900,
      borderColor: transparent,
      backgroundColor: transparent,
      hover: {
        color: whiteR900,
        borderColor: blueA700,
        backgroundColor: blueA700,
      },
    };
  }

  const grayA100 = getColor({ commonType: EColor.GRAY, intensity: EColor.A100 });
  const grayA200 = getColor({ commonType: EColor.GRAY, intensity: EColor.A200 });

  if (color === EColor.ACCENT) {
    return {
      color: getColor(EColor.SECONDARY),
      borderColor: grayA100,
      backgroundColor: grayA100,
      hover: {
        color: getColor({ semanticType: EColor.SECONDARY, variant: EColor.DARK }),
        borderColor: grayA200,
        backgroundColor: grayA200,
      },
    };
  }

  return {
    color: getColor(EColor.PRIMARY),
    borderColor: grayA100,
    backgroundColor: grayA100,
    hover: {
      color: getColor({ semanticType: EColor.PRIMARY, variant: EColor.DARK }),
      borderColor: grayA200,
      backgroundColor: grayA200,
    },
  };
};

export const getButtonColorProps = ({
  color,
  palette,
  variant,
  isDisabled,
  isTransparent,
}: {
  color: ButtonColorType;
  palette: ThemePalette;
  variant: EButtonVariant;
  isDisabled?: boolean;
  isTransparent?: boolean;
}): ButtonColorProps => {
  const { getColor } = getPaletteHandlers(palette);

  if (variant === EButtonVariant.OUTLINED) {
    return getButtonOutlinedColorProps({ color, getColor, isDisabled, isTransparent });
  }

  if (variant === EButtonVariant.TEXT) {
    return getButtonTextColorProps({ color, isDisabled, getColor });
  }

  return getButtonContainedColorProps({ color, isDisabled, getColor });
};
