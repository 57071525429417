import { HTMLAttributeAnchorTarget, ReactNode } from 'react';

import {
  ColorProps,
  EPosition,
  ERouting,
  IconPosition,
  PositionType,
  StyleRuleWidth,
  TextColorType,
} from '@core/type';

export type DrawerPosition = keyof PositionType<
  EPosition.TOP | EPosition.RIGHT | EPosition.BOTTOM | EPosition.LEFT
>;

export enum EDrawerLevel {
  DRAWER = 'drawer',
  CURRENCY = 'currency',
  LANGUAGE = 'language',
  SHOP = 'shop',
  CHARTS = 'charts',
}

export type DrawerProps = {
  children?: ReactNode;
  position: DrawerPosition;
  backgroundColor?: string;
  size?: string;
  iconOrientation?: IconPosition;
  iconWidth?: StyleRuleWidth;
  iconColor?: ColorProps<TextColorType>;
  drawerLevel: EDrawerLevel | `${EDrawerLevel}/${string}`;
  drawerLevelProps: Partial<Record<EDrawerLevel | `${EDrawerLevel}/${string}`, boolean>>;
  handleDrawerLevelProps: (props: Partial<Record<EDrawerLevel, boolean>>) => void;
};

export type DrawerLineProps = Pick<DrawerProps, 'drawerLevelProps' | 'handleDrawerLevelProps'> &
  Partial<Pick<DrawerProps, 'drawerLevel'>> & {
    children?: ReactNode;
    title: ReactNode;
    route?: ERouting;
    query?: NodeJS.Dict<string | string[]>;
    isInvalid?: boolean;
    target?: HTMLAttributeAnchorTarget;
    href?: string;
  };

export type SubDrawerProps = Pick<
  DrawerProps,
  'drawerLevel' | 'drawerLevelProps' | 'handleDrawerLevelProps'
> & {
  children?: ReactNode;
  position: DrawerPosition;
  backgroundColor?: string;
  size?: string;
  title?: ReactNode;
  listTitle?: ReactNode;
};

export type DrawerPositionProps = {
  [key in DrawerPosition]: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
};
