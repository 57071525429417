import { EColor, EColorName, ThemePalette } from '@core/type';

export const themeDefaultPalette: ThemePalette = {
  [EColor.PRIMARY]: {
    [EColor.MAIN]: EColorName.CHATHAMS_BLUE,
    [EColor.LIGHT]: EColorName.COBALT,
    [EColor.DARK]: EColorName.BLUE_ZODIAC,
  },
  [EColor.SECONDARY]: {
    [EColor.MAIN]: EColorName.DODGER_BLUE,
    [EColor.LIGHT]: null,
    [EColor.DARK]: EColorName.BLUE_RIBBON,
  },
  [EColor.SUCCESS]: {
    [EColor.MAIN]: EColorName.JADE,
    [EColor.LIGHT]: EColorName.BLACK_SQUEEZE,
    [EColor.DARK]: null,
  },
  [EColor.WARNING]: {
    [EColor.MAIN]: EColorName.OLD_GOLD,
    [EColor.LIGHT]: EColorName.QUARTER_PEARL_LUSTA,
    [EColor.DARK]: EColorName.SANDY_BROWN,
  },
  [EColor.DANGER]: {
    [EColor.MAIN]: EColorName.BURNT_SIENNA,
    [EColor.LIGHT]: EColorName.CHABLIS,
    [EColor.DARK]: null,
  },
  [EColor.INFO]: {
    [EColor.MAIN]: EColorName.BERMUDA_GRAY,
    [EColor.LIGHT]: null,
    [EColor.DARK]: null,
  },
  [EColor.NEUTRAL]: {
    [EColor.MAIN]: EColorName.HEATHER,
    [EColor.LIGHT]: null,
    [EColor.DARK]: null,
  },
  [EColor.BLACK]: {
    [EColor.R100]: EColorName.BLACK_12,
    [EColor.R200]: EColorName.BLACK_14,
    [EColor.R300]: EColorName.BLACK_20,
    [EColor.R900]: EColorName.BLACK,
  },
  [EColor.BLUE]: {
    [EColor.R50]: EColorName.COBALT_05,
    [EColor.R100]: EColorName.DODGER_BLUE_07,
    [EColor.R200]: EColorName.CHATHAMS_BLUE_20,
    [EColor.R300]: EColorName.CHATHAMS_BLUE_30,
    [EColor.R400]: EColorName.CHATHAMS_BLUE,
    [EColor.R500]: EColorName.COBALT,
    [EColor.R600]: EColorName.CATALINA_BLUE,
    [EColor.R700]: EColorName.BLUE_ZODIAC,
    [EColor.R900]: EColorName.MAASTRICHT_BLUE,
    [EColor.A50]: EColorName.ALICE_BLUE,
    [EColor.A100]: EColorName.PATTENS_BLUE,
    [EColor.A200]: EColorName.MALIBU,
    [EColor.A300]: EColorName.DODGER_BLUE,
    [EColor.A400]: EColorName.BLUE_RIBBON,
    [EColor.A500]: EColorName.PERSIAN_BLUE,
    [EColor.A600]: EColorName.MOODY_BLUE,
    [EColor.A700]: EColorName.MIDNIGHT_BLUE,
  },
  [EColor.GRAY]: {
    [EColor.R50]: EColorName.MOUNTAIN_MIST_01,
    [EColor.R100]: EColorName.MOUNTAIN_MIST_10,
    [EColor.R200]: EColorName.RIVER_BED_12,
    [EColor.R300]: EColorName.RIVER_BED_15,
    [EColor.R400]: EColorName.RIVER_BED_20,
    [EColor.R500]: EColorName.RIVER_BED_26,
    [EColor.R600]: EColorName.RIVER_BED,
    [EColor.R700]: EColorName.BERMUDA_GRAY,
    [EColor.R800]: EColorName.BLUISH_GRAY,
    [EColor.A50]: EColorName.ATHENS_GRAY,
    [EColor.A100]: EColorName.GHOST_WHITE,
    [EColor.A200]: EColorName.MYSTIC,
    [EColor.A300]: EColorName.PORCELAIN,
    [EColor.A400]: EColorName.HEATHER,
    [EColor.A500]: EColorName.GALLERY,
    [EColor.A600]: EColorName.MOUNTAIN_MIST,
    [EColor.A700]: EColorName.SKELETON_GRAY,
    [EColor.A800]: EColorName.SKELETON_WHITE,
    [EColor.A900]: EColorName.BLACK_HAZE,
  },
  [EColor.GREEN]: {
    [EColor.R50]: EColorName.JADE_09,
    [EColor.R100]: EColorName.JADE_20,
    [EColor.R700]: EColorName.JADE,
    [EColor.A100]: EColorName.BLACK_SQUEEZE,
    [EColor.A200]: EColorName.POLAR,
    [EColor.R300]: EColorName.CHARLOTTE,
    [EColor.A500]: EColorName.BRIGHT_TURQUOISE,
    [EColor.A900]: EColorName.BLUE_STONE,
  },
  [EColor.PURPLE]: {
    [EColor.R100]: EColorName.LAVENDER,
    [EColor.R300]: EColorName.DARK_PERIWINKLE,
    [EColor.R400]: EColorName.COMET,
    [EColor.R500]: EColorName.ROYAL_BLUE,
    [EColor.R600]: EColorName.PURPLE_HEART,
    [EColor.R900]: EColorName.SCARLET_GUM,
  },
  [EColor.RED]: {
    [EColor.R50]: EColorName.BURNT_SIENNA_33,
    [EColor.R300]: EColorName.BLUSH,
    [EColor.R500]: EColorName.BURNT_SIENNA,
    [EColor.A100]: EColorName.CHABLIS,
    [EColor.A200]: EColorName.CAROUSEL_PINK,
    [EColor.A300]: EColorName.LAVENDER_BLUSH,
    [EColor.A800]: EColorName.CERISE,
  },
  [EColor.WHITE]: {
    [EColor.R100]: EColorName.WHITE_10,
    [EColor.R300]: EColorName.WHITE_40,
    [EColor.R600]: EColorName.WHITE_70,
    [EColor.R700]: EColorName.WHITE_80,
    [EColor.R900]: EColorName.WHITE,
  },
  [EColor.YELLOW]: {
    [EColor.R50]: EColorName.OLD_GOLD_20,
    [EColor.R100]: EColorName.PEARL_LUSTA,
    [EColor.R200]: EColorName.QUARTER_PEARL_LUSTA,
    [EColor.R300]: EColorName.CAPE_HONEY,
    [EColor.R400]: EColorName.WHEAT,
    [EColor.R500]: EColorName.OLD_GOLD,
    [EColor.R600]: EColorName.SANDY_BROWN,
    [EColor.R700]: EColorName.OLD_GOLD_12,
  },
  [EColor.TEXT]: {
    [EColor.SECONDARY]: {
      [EColor.MAIN]: EColorName.BERMUDA_GRAY,
      [EColor.LIGHT]: null,
      [EColor.DARK]: EColorName.BLUE_RIBBON,
    },
    [EColor.ACCENT]: {
      [EColor.MAIN]: EColorName.DODGER_BLUE,
      [EColor.LIGHT]: null,
      [EColor.DARK]: EColorName.BLUE_RIBBON,
    },
    [EColor.DESTRUCTIVE]: {
      [EColor.MAIN]: EColorName.BERMUDA_GRAY,
      [EColor.LIGHT]: null,
      [EColor.DARK]: EColorName.BURNT_SIENNA,
    },
    [EColor.DANGER]: {
      [EColor.MAIN]: EColorName.BURNT_SIENNA,
      [EColor.LIGHT]: EColorName.BURNT_SIENNA,
      [EColor.DARK]: EColorName.BURNT_SIENNA,
    },
  },
  [EColor.BACKGROUND]: {
    [EColor.SECONDARY]: {
      [EColor.MAIN]: EColorName.ATHENS_GRAY,
      [EColor.LIGHT]: EColorName.GHOST_WHITE,
      [EColor.DARK]: null,
    },
    [EColor.ACCENT]: {
      [EColor.MAIN]: EColorName.DODGER_BLUE,
      [EColor.LIGHT]: EColorName.MALIBU,
      [EColor.DARK]: null,
    },
    [EColor.WARNING]: {
      [EColor.MAIN]: EColorName.SANDY_BROWN,
      [EColor.LIGHT]: EColorName.QUARTER_PEARL_LUSTA,
      [EColor.DARK]: null,
    },
    [EColor.INFO]: {
      [EColor.MAIN]: EColorName.ALICE_BLUE,
      [EColor.LIGHT]: EColorName.WHITE,
      [EColor.DARK]: null,
    },
    [EColor.NEUTRAL]: {
      [EColor.MAIN]: EColorName.HEATHER,
      [EColor.LIGHT]: EColorName.WHITE,
      [EColor.DARK]: null,
    },
  },
  [EColor.BOX_SHADOW]: {
    [EColor.ELEVATION_1]: `0 2px 4px -1px ${EColorName.BLACK_20}, 0 1px 10px 0 ${EColorName.BLACK_12}, 0 4px 5px 0 ${EColorName.BLACK_14}`,
    [EColor.ELEVATION_2]: `0 2px 12px ${EColorName.RIVER_BED_26}`,
    [EColor.ELEVATION_3]: `0 8px 15px ${EColorName.RIVER_BED_15}`,
    [EColor.ELEVATION_4]: `0 10px 24px ${EColorName.RIVER_BED_12}`,
    [EColor.ELEVATION_5]: `0 16px 40px ${EColorName.RIVER_BED_20}`,
    [EColor.ELEVATION_6]: `0 24px 40px ${EColorName.RIVER_BED_15}`,
  },
};
