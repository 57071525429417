export enum ELayout {
  DEFAULT = 'default',
  BLOG = 'blog',
  SHORT = 'short',
  LANDING = 'landing',
}

export type LayoutType<Layout extends Partial<ELayout>> = {
  [key in Layout]: Layout;
};

export type HeaderLayoutType = keyof LayoutType<
  ELayout.DEFAULT | ELayout.BLOG | ELayout.SHORT | ELayout.LANDING
>;
