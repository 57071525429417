import withTranslation from 'next-translate/withTranslation';

import { DrawerLine, Line } from '@core/component';
import { Translate } from '@core/constant';
import { useQueryApi } from '@core/hook';
import { EPosition, ERouting, WithTranslation } from '@core/type';
import { BlogCategoriesResponse, EQueryKey } from '@core/type/api';

import { LanguageDrawerLine } from './LanguageDrawerLine';
import { SideNavBlogProps } from './interface-header';

const SideNavBlogContainer = ({
  i18n: { t },
  shouldShowCurrencyLink,
  position = EPosition.LEFT,
  closeDrawer,
  drawerLevelProps,
  handleDrawerLevelProps,
}: WithTranslation & SideNavBlogProps) => {
  const { data: blogCategories } = useQueryApi<BlogCategoriesResponse>(EQueryKey.BLOG_CATEGORIES);

  return (
    <>
      <DrawerLine
        title={t('blog.whatIsGa')}
        route={ERouting.HOME}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={closeDrawer}
      />
      <Line margin="24px 16px 24px 40px" />
      <DrawerLine
        title={t('blog.allArticles')}
        route={ERouting.BLOG_HOME}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={closeDrawer}
      />
      {blogCategories?.blogCategories
        ?.sort((a, b) => a.position - b.position)
        .map(({ slug, title }) => (
          <DrawerLine
            title={title}
            route={ERouting.BLOG_CATEGORY}
            query={{ slug }}
            drawerLevelProps={drawerLevelProps}
            handleDrawerLevelProps={closeDrawer}
            key={slug}
          />
        ))}
      <Line margin="24px 16px 24px 40px" />
      <LanguageDrawerLine
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={handleDrawerLevelProps}
        shouldShowCurrencyLink={shouldShowCurrencyLink}
        position={position}
      />
    </>
  );
};

SideNavBlogContainer.displayName = 'SideNavBlog';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const SideNavBlog = withTranslation(SideNavBlogContainer, Translate.layout.HEADER);
