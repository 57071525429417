import Image from 'next/image';

import IconGraphSvg from './svgs/icon-graph.svg';

export const IconGraph = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconGraphSvg}
    alt="Graph icon"
    height={20}
    width={20}
  />
);
