import Image from 'next/image';

import IconCartSvg from './svgs/icon-cart.svg';

export const IconCart = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconCartSvg}
    alt="Cart icon"
    width={24}
    height={24}
  />
);
