import { useEffect, useRef } from 'react';

import { WebSocketService } from '@core/api';
import { useStoreCurrency, useStoreMetalPrice, useStoreUtil } from '@core/context';
import { useQueryApi } from '@core/hook';
import { ECurrency, EMetal, EWebSocketIdentifier, MetalPrice, MetalsPrice } from '@core/type';
import { EQueryKey, SpotPriceEntity } from '@core/type/api';
import { HookStoreFn, MetalPriceHook, MetalPriceState } from '@core/type/context';

export const makeUseMetalPrice = (wsHost: string) => {
  const useMetalPrice: HookStoreFn<MetalPriceState, MetalPriceHook> = (): MetalPriceHook => {
    const [currency] = useStoreCurrency<ECurrency>('currency');
    const [prices, setPrices] = useStoreMetalPrice<MetalsPrice>('prices');
    const [isBotDetected] = useStoreUtil<boolean>('isBotDetected');

    const websocket = useRef<WebSocket>(null);

    const { data, isSuccess } = useQueryApi<{
      spotPrices: SpotPriceEntity[];
    }>(EQueryKey.SPOT_PRICES, null, {
      enabled: !!currency,
      retryOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    });

    const convertSpotPriceEntity = (
      entities: SpotPriceEntity[],
      metalIso: EMetal,
    ): Omit<MetalPrice, 'name'> => {
      return entities
        ?.filter((entity) => entity.metalIso === metalIso)
        ?.map(({ priceChange, deskask, deskbid }) => ({
          isPositive: priceChange >= 0,
          deskask,
          deskbid,
          priceChange,
        }))[0];
    };

    const convertSpotPriceEntities = (entities: SpotPriceEntity[]): MetalsPrice => {
      if (entities && Array.isArray(entities)) {
        return {
          [EMetal.GOLD]: {
            ...prices[EMetal.GOLD],
            ...convertSpotPriceEntity(entities, EMetal.GOLD),
          },
          [EMetal.SILVER]: {
            ...prices[EMetal.SILVER],
            ...convertSpotPriceEntity(entities, EMetal.SILVER),
          },
          [EMetal.PLATINUM]: {
            ...prices[EMetal.PLATINUM],
            ...convertSpotPriceEntity(entities, EMetal.PLATINUM),
          },
          [EMetal.PALLADIUM]: {
            ...prices[EMetal.PALLADIUM],
            ...convertSpotPriceEntity(entities, EMetal.PALLADIUM),
          },
        };
      }
      return prices;
    };

    useEffect(() => {
      if (isSuccess) {
        setPrices(convertSpotPriceEntities(data.spotPrices));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, currency]);

    useEffect(() => {
      if (!isBotDetected) {
        websocket.current = WebSocketService.open(wsHost, EWebSocketIdentifier.METAL_PRICES, {
          IsoCurrency: currency,
        });
        WebSocketService.message(
          websocket.current,
          EWebSocketIdentifier.METAL_PRICES,
          (state: SpotPriceEntity[]) => {
            setPrices(convertSpotPriceEntities(state));
          },
        );
      }

      return () => {
        if (websocket.current) {
          websocket.current.close();
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currency]);

    return {
      prices,
      setPrices,
    };
  };

  return useMetalPrice;
};
