import { FC, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { useContextMediaQuery } from '@core/context';
import { EActionAttribute, EColor, EFontWeight, EIcon, ESize, WithThemeProps } from '@core/type';

import { fadeInAnimation, fadeOutAnimation } from '../animation';
import { Icon } from '../icon';
import { Box } from '../layout';
import { Paper } from '../paper';
import { PaperProps } from '../paper/interface-paper';
import { Typography } from '../typography';
import { BannerColorProps, BannerProps } from './interface-banner';

const BANNER_CLOSING_TIMEOUT = 300;

const ExternalContainer: FC<PaperProps & { isOpen: boolean }> = styled(Paper)(
  ({
    isOpen,
    theme: {
      structure: {
        header: { maxWidth },
      },
    },
  }: { isOpen: boolean } & WithThemeProps) => css`
    max-width: ${maxWidth};
    animation: 0.3s linear ${isOpen ? fadeInAnimation : fadeOutAnimation};
  `,
);

export const Banner = ({
  color,
  onClick,
  title,
  hasLogo = true,
  hasCrossIcon = true,
  text,
  iconProps,
  button,
  onClose,
  isOpen,
  ...restContainerProps
}: BannerProps) => {
  const { isPhone } = useContextMediaQuery();

  const [isClosing, setIsClosing] = useState<boolean>(false);
  const [isVisibleOnLoad, setIsVisibleOnLoad] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setIsClosing(true);
      setIsVisibleOnLoad(true);
    } else {
      setIsClosing(true);
      setTimeout(() => {
        setIsClosing(false);
      }, BANNER_CLOSING_TIMEOUT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const colorProps: BannerColorProps = useMemo(() => {
    const successProps: BannerColorProps = {
      color: EColor.SUCCESS,
      borderColor: { commonType: EColor.GREEN, intensity: EColor.R100 },
      backgroundColor: { semanticType: EColor.SUCCESS, variant: EColor.LIGHT },
    };

    switch (color) {
      case EColor.SUCCESS:
        return successProps;
      case EColor.INFO:
        return {
          color: EColor.PRIMARY,
          borderColor: { commonType: EColor.BLUE, intensity: EColor.R200 },
          backgroundColor: EColor.INFO,
        };
      case EColor.WARNING:
        return {
          color: EColor.WARNING,
          borderColor: EColor.WARNING,
          backgroundColor: { semanticType: EColor.WARNING, variant: EColor.LIGHT },
        };
      case EColor.DANGER:
        return {
          color: EColor.DANGER,
          borderColor: { commonType: EColor.RED, intensity: EColor.R50 },
          backgroundColor: { semanticType: EColor.DANGER, variant: EColor.LIGHT },
        };
      default:
        return successProps;
    }
  }, [color]);

  return isVisibleOnLoad && (isOpen || isClosing) ? (
    <ExternalContainer
      hasElevation
      position={'relative'}
      margin={'0 auto'}
      padding={'24px 64px 24px 24px'}
      display={'flex'}
      justifyContent={button ? 'space-between' : 'flex-start'}
      {...colorProps}
      {...restContainerProps}
      isOpen={isOpen}
    >
      {!isPhone && hasLogo && (
        <Box marginRight="20px">
          <Icon
            type={EIcon.GA_LOGO}
            display="inline-block"
            size={ESize.LG}
            color={colorProps.color}
          />
        </Box>
      )}

      <Box>
        {title && (
          <Typography as={EActionAttribute.SPAN} fontWeight={EFontWeight.BOLD} marginRight="3px">
            {title}
          </Typography>
        )}
        <Typography as={EActionAttribute.SPAN}>{text}</Typography>
      </Box>
      {button}
      {hasCrossIcon && (
        <Icon
          type={EIcon.CROSS}
          position="absolute"
          top={'24px'}
          right={'40px'}
          color={colorProps.color}
          hoverStyles={{ cursor: 'pointer' }}
          onClick={onClose}
          {...iconProps}
        />
      )}
    </ExternalContainer>
  ) : null;
};
