import { MouseEventHandler } from 'react';

import { useLanguage } from '@core/hook';
import { EActionAttribute, EPosition } from '@core/type';

import { ErrorDot } from '../error-dot';
import { Box } from '../layout';
import { Link } from '../link';
import { LinkTranslatedStyledAsLink } from '../link-translated';
import { DrawerSub } from './DrawerSub';
import { DrawerLineProps } from './interface-drawer';

const DrawerTextLine = ({ children, title }: Pick<DrawerLineProps, 'children' | 'title'>) => (
  <Box display={'flex'} alignItems={'center'} justifyContent="space-between">
    <Box>{title}</Box>
    {children}
  </Box>
);

const DrawerLinkLine = ({
  children,
  title,
  route,
  query,
  isInvalid,
  drawerLevel,
  handleDrawerLevelProps,
  target,
  href,
}: DrawerLineProps) => {
  const { language } = useLanguage();
  const handleClick: MouseEventHandler<HTMLAnchorElement> = () => {
    handleDrawerLevelProps({ [drawerLevel]: false });
  };

  return route || href ? (
    <LinkTranslatedStyledAsLink
      keyRoute={route}
      query={query}
      onClick={handleClick}
      target={target}
      language={language}
      href={href}
      isUnderlinedHover={false}
    >
      <DrawerTextLine title={title}>
        {children}
        {isInvalid && <ErrorDot />}
      </DrawerTextLine>
    </LinkTranslatedStyledAsLink>
  ) : (
    <Link onClick={handleClick}>
      <DrawerTextLine title={title}>
        {children}
        {isInvalid && <ErrorDot />}
      </DrawerTextLine>
    </Link>
  );
};

export const DrawerLine = ({
  children,
  title,
  route,
  query,
  drawerLevel,
  drawerLevelProps,
  handleDrawerLevelProps,
  isInvalid,
  target,
  href,
}: DrawerLineProps) => (
  <Box as={EActionAttribute.LI} padding="8px 16px 8px 40px">
    {children ? (
      <DrawerSub
        title={title}
        drawerLevel={drawerLevel}
        position={EPosition.LEFT}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={handleDrawerLevelProps}
      >
        {children}
      </DrawerSub>
    ) : (
      <DrawerLinkLine
        drawerLevel={drawerLevel}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={handleDrawerLevelProps}
        title={title}
        route={route}
        query={query}
        isInvalid={isInvalid}
        target={target}
        href={href}
      />
    )}
  </Box>
);
