import Image from 'next/image';

import IconCartRepeatSvg from './svgs/icon-cart-repeat.svg';

export const IconCartRepeat = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconCartRepeatSvg}
    alt="Cart repeat icon"
    height={24}
    width={24}
  />
);
