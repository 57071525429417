export enum EColor {
  // semantic types
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ACCENT = 'accent',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  DESTRUCTIVE = 'destructive',
  INFO = 'info',
  NEUTRAL = 'neutral',

  // common types
  BLACK = 'black',
  BLUE = 'blue',
  GRAY = 'gray',
  GREEN = 'green',
  RED = 'red',
  WHITE = 'white',
  YELLOW = 'yellow',
  PURPLE = 'purple',

  // variant types
  MAIN = 'main',
  LIGHT = 'light',
  DARK = 'dark',

  // design element types
  TEXT = 'text',
  BACKGROUND = 'background',
  BOX_SHADOW = 'boxShadow',

  // intensity types. R - Regular, A - Accent.
  R50 = 'r50',
  R100 = 'r100',
  R200 = 'r200',
  R300 = 'r300',
  R400 = 'r400',
  R500 = 'r500',
  R600 = 'r600',
  R700 = 'r700',
  R800 = 'r800',
  R900 = 'r900',
  A50 = 'a50',
  A100 = 'a100',
  A200 = 'a200',
  A300 = 'a300',
  A400 = 'a400',
  A500 = 'a500',
  A600 = 'a600',
  A700 = 'a700',
  A800 = 'a800',
  A900 = 'a900',

  // box shadow elevation types
  ELEVATION_1 = 'elevation1',
  ELEVATION_2 = 'elevation2',
  ELEVATION_3 = 'elevation3',
  ELEVATION_4 = 'elevation4',
  ELEVATION_5 = 'elevation5',
  ELEVATION_6 = 'elevation6',

  // other types
  TRANSPARENT = 'transparent',
}

/* For adding opacity to the hex color, need to put value of opacity after hex color.
 Each % of opacity has specific value.
 For example, for #000000 (black color) with opacity 90% it will be: #000000E6.
 All hex value from 100% to 0% alpha:
 100% — FF
 99% — FC
 98% — FA
 97% — F7
 96% — F5
 95% — F2
 94% — F0
 93% — ED
 92% — EB
 91% — E8
 90% — E6
 89% — E3
 88% — E0
 87% — DE
 86% — DB
 85% — D9
 84% — D6
 83% — D4
 82% — D1
 81% — CF
 80% — CC
 79% — C9
 78% — C7
 77% — C4
 76% — C2
 75% — BF
 74% — BD
 73% — BA
 72% — B8
 71% — B5
 70% — B3
 69% — B0
 68% — AD
 67% — AB
 66% — A8
 65% — A6
 64% — A3
 63% — A1
 62% — 9E
 61% — 9C
 60% — 99
 59% — 96
 58% — 94
 57% — 91
 56% — 8F
 55% — 8C
 54% — 8A
 53% — 87
 52% — 85
 51% — 82
 50% — 80
 49% — 7D
 48% — 7A
 47% — 78
 46% — 75
 45% — 73
 44% — 70
 43% — 6E
 42% — 6B
 41% — 69
 40% — 66
 39% — 63
 38% — 61
 37% — 5E
 36% — 5C
 35% — 59
 34% — 57
 33% — 54
 32% — 52
 31% — 4F
 30% — 4D
 29% — 4A
 28% — 47
 27% — 45
 26% — 42
 25% — 40
 24% — 3D
 23% — 3B
 22% — 38
 21% — 36
 20% — 33
 19% — 30
 18% — 2E
 17% — 2B
 16% — 29
 15% — 26
 14% — 24
 13% — 21
 12% — 1F
 11% — 1C
 10% — 1A
 9% — 17
 8% — 14
 7% — 12
 6% — 0F
 5% — 0D
 4% — 0A
 3% — 08
 2% — 05
 1% — 03
 0% — 00
 * */

export enum EColorName {
  // Blues
  // Regular shades
  COBALT_05 = '#1d477a05',
  DODGER_BLUE_07 = '#378bf212',
  CHATHAMS_BLUE_20 = '#19447533',
  CHATHAMS_BLUE_30 = '#1944754D',
  CHATHAMS_BLUE = '#194475',
  COBALT = '#1d477a',
  CATALINA_BLUE = '#173966',
  BLUE_ZODIAC = '#153358',
  MAASTRICHT_BLUE = '#0b1b2e',
  // Accent shades
  ALICE_BLUE = '#f0f9ff',
  PATTENS_BLUE = '#e0f0ff',
  MALIBU = '#62a8ff',
  DODGER_BLUE = '#378bf2',
  BLUE_RIBBON = '#006aeb',
  PERSIAN_BLUE = '#1840c0',
  MOODY_BLUE = '#6d7dd7',
  MIDNIGHT_BLUE = '#345178',

  // Grays
  // Regulars shades
  MOUNTAIN_MIST_01 = '#97979703',
  MOUNTAIN_MIST_10 = '#9797971A',
  RIVER_BED_12 = '#404b571F',
  RIVER_BED_15 = '#404b5726',
  RIVER_BED_20 = '#404b5733',
  RIVER_BED_26 = '#404b5742',
  RIVER_BED = '#404b57',
  BERMUDA_GRAY = '#758fac',
  BLUISH_GRAY = '#7b8b9e',
  SKELETON_GRAY = '#f5f5f5',
  SKELETON_WHITE = '#f9f9f9',
  // Accent shades
  ATHENS_GRAY = '#f5f6f8',
  BLACK_HAZE = '#FAFBFB',
  GHOST_WHITE = '#f7f8fa',
  MYSTIC = '#eaeef2',
  PORCELAIN = '#eff1f2',
  HEATHER = '#bac7d6',
  GALLERY = '#eeeeee',
  MOUNTAIN_MIST = '#979797',

  // Blacks
  // Regular shades
  BLACK_12 = '#0000001F',
  BLACK_14 = '#00000024',
  BLACK_20 = '#00000033',
  BLACK = '#000000',

  // Reds
  // Regular shades
  BURNT_SIENNA_33 = '#eb575733',
  BLUSH = '#f38181',
  BURNT_SIENNA = '#eb5757',
  // Accent shades
  CHABLIS = '#fff5f5',
  CAROUSEL_PINK = '#fcf0f7',
  LAVENDER_BLUSH = '#fceaf3',
  CERISE = '#de2f80',

  // Purples
  // Regular shades
  LAVENDER = '#c86dd7',
  DARK_PERIWINKLE = '#6d7ed7',
  COMET = '#565378',
  ROYAL_BLUE = '#5854ED',
  PURPLE_HEART = '#4d32b6',
  SCARLET_GUM = '#502c61',

  // Yellows
  // Regular shades
  OLD_GOLD_12 = '#d4af501F',
  OLD_GOLD_20 = '#d4af5033',
  PEARL_LUSTA = '#fcf4db',
  QUARTER_PEARL_LUSTA = '#fffcf5',
  CAPE_HONEY = '#feecb3',
  WHEAT = '#fce38a',
  OLD_GOLD = '#d4af50',
  SANDY_BROWN = '#bfa065',

  // Greens
  // Regular shades
  JADE_09 = '#09bb7017',
  JADE_20 = '#09bb7033',
  JADE = '#09bb70',
  // Accent shades
  BLACK_SQUEEZE = '#f2faf7',
  POLAR = '#effafb',
  CHARLOTTE = '#a2f3f7',
  BRIGHT_TURQUOISE = '#20e9c7',
  BLUE_STONE = '#106657',

  // Whites
  // Regular shades
  WHITE_10 = '#ffffff1A',
  WHITE_40 = '#ffffff66',
  WHITE_70 = '#ffffffB3',
  WHITE_80 = '#ffffffCC',
  WHITE = '#ffffff',

  // Other
  TRANSPARENT = 'transparent',
}

export type ColorType<Color extends Partial<EColor>, P = EColorName> = {
  [key in Color]: P;
};

export type SemanticColorType = keyof ColorType<
  | EColor.PRIMARY
  | EColor.SECONDARY
  | EColor.SUCCESS
  | EColor.WARNING
  | EColor.DANGER
  | EColor.INFO
  | EColor.NEUTRAL
>;

export type CommonColorType = keyof ColorType<
  | EColor.BLACK
  | EColor.BLUE
  | EColor.GRAY
  | EColor.GREEN
  | EColor.PURPLE
  | EColor.RED
  | EColor.WHITE
  | EColor.YELLOW
>;

export type VariantColorType = keyof ColorType<EColor.MAIN | EColor.LIGHT | EColor.DARK>;

export type IntensityColorType = keyof ColorType<
  | EColor.A50
  | EColor.A100
  | EColor.A200
  | EColor.A300
  | EColor.A400
  | EColor.A500
  | EColor.A600
  | EColor.A700
  | EColor.A800
  | EColor.A900
  | EColor.R50
  | EColor.R100
  | EColor.R200
  | EColor.R300
  | EColor.R400
  | EColor.R500
  | EColor.R600
  | EColor.R700
  | EColor.R800
  | EColor.R900
>;

export type TextColorType = keyof ColorType<
  EColor.SECONDARY | EColor.ACCENT | EColor.DESTRUCTIVE | EColor.DANGER
>;

export type BackgroundColorType = keyof ColorType<
  EColor.SECONDARY | EColor.ACCENT | EColor.WARNING | EColor.INFO | EColor.NEUTRAL
>;

export type BoxShadowElevationType = keyof ColorType<
  | EColor.ELEVATION_1
  | EColor.ELEVATION_2
  | EColor.ELEVATION_3
  | EColor.ELEVATION_4
  | EColor.ELEVATION_5
  | EColor.ELEVATION_6
>;

export type DesignElementType = keyof ColorType<EColor.TEXT | EColor.BACKGROUND>;

export type SemanticColorProps<T extends SemanticColorType | TextColorType | BackgroundColorType> =
  {
    semanticType: SemanticColorType | T;
    variant?: VariantColorType;
  };

export type CommonColorProps = {
  commonType: CommonColorType;
  intensity: IntensityColorType;
};

export type ColorProps<T extends TextColorType | BackgroundColorType = null> =
  | SemanticColorType
  | T
  | EColor.TRANSPARENT
  | SemanticColorProps<T>
  | CommonColorProps;
