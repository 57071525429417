import { EActionAttribute, EColor } from '@core/type';

import { Box } from '../layout';

export const ErrorDot = () => (
  <Box
    as={EActionAttribute.SPAN}
    backgroundColor={EColor.DANGER}
    display="inline-block"
    width="12px"
    height="12px"
    borderRadius="50%"
  />
);
