import {
  EAccessRoute,
  ELayout,
  ERouting,
  ERoutingDynamic,
  TypeRoute,
  TypeRoutes,
} from '@core/type';

import { DEFAULT_SITEMAP_PROPERTIES } from './sitemap';
import { Translate } from './translate';

const defaultHeader: Pick<
  TypeRoute,
  'header' | 'breadcrumb' | 'accessRoute' | 'sitemap' | 'noFooter' | 'noHeader' | 'isDarkBackground'
> = {
  header: {
    type: ELayout.DEFAULT,
    isLight: false,
  },
  accessRoute: EAccessRoute.DEFAULT,
  sitemap: DEFAULT_SITEMAP_PROPERTIES,
  breadcrumb: null,
  noFooter: {},
  noHeader: {},
  isDarkBackground: {},
};

const onboardingRouteProps: Omit<TypeRoute, 'accessRoute' | 'translation'> = {
  ...defaultHeader,
  header: {
    type: ELayout.SHORT,
    hasSelectCurrency: false,
    isLight: true,
    hasSelectLanguage: true,
  },
  noFooter: { isHidden: true },
  isFullHeightPage: true,
  noPaddingMargin: true,
};

export const typeRoute: TypeRoutes = {
  [ERouting.ABOUT_US]: {
    ...defaultHeader,
    breadcrumb: [ERouting.HOME],
    header: {
      type: ELayout.DEFAULT,
      isLight: false,
      shouldShowBanner: true,
    },
    translation: Translate.page.ABOUT_US,
  },
  [ERouting.AUTO_SAVINGS]: {
    ...defaultHeader,
    breadcrumb: [ERouting.HOME],
    header: {
      type: ELayout.DEFAULT,
      isLight: false,
      shouldShowBanner: true,
    },
    translation: Translate.page.AUTO_SAVINGS,
    isFullWidthPage: true,
  },
  [ERouting.SIGN_UP]: {
    ...onboardingRouteProps,
    accessRoute: EAccessRoute.NOT_CONNECTED,
    translation: Translate.page.SIGN_UP,
    noIndex: true,
  },
  [ERouting.DONE_SIGN_UP]: {
    ...onboardingRouteProps,
    accessRoute: EAccessRoute.DEFAULT,
    translation: Translate.page.SIGN_UP,
  },
  [ERouting.CHARTS]: {
    ...defaultHeader,
    keyRouteDynamic: ERoutingDynamic.CHARTS,
    keyRouteParent: ERouting.HOME,
    header: {
      type: ELayout.DEFAULT,
      isLight: false,
      shouldShowCurrencyLink: true,
    },
    breadcrumb: [],
    translation: Translate.page.CHARTS,
    hasCustomSitemap: true,
  },
  [ERouting.KYC_PHOTO_IDENTIFICATION]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    header: {
      type: ELayout.SHORT,
      isLight: true,
      hasSelectCurrency: true,
      hasSelectLanguage: true,
    },
    noFooter: { isHidden: true },
    isFullHeightPage: true,
    noPaddingMargin: true,
    translation: Translate.page.PHOTO_IDENTIFICATION,
  },
  [ERouting.KYC_DONE]: {
    ...onboardingRouteProps,
    accessRoute: EAccessRoute.CONNECTED,
    translation: Translate.page.DONE_KYC,
  },
  [ERouting.KYC_DATE_OF_BIRTH]: {
    ...onboardingRouteProps,
    accessRoute: EAccessRoute.CONNECTED,
    translation: Translate.page.KYC_DATE_OF_BIRTH,
  },
  [ERouting.KYC_PHONE_NUMBER]: {
    ...onboardingRouteProps,
    accessRoute: EAccessRoute.CONNECTED,
    translation: Translate.page.KYC_PHONE_NUMBER,
  },
  [ERouting.KYC_NATIONALITIES]: {
    ...onboardingRouteProps,
    accessRoute: EAccessRoute.CONNECTED,
    translation: Translate.page.NATIONALITY,
  },
  [ERouting.KYC_COUNTRIES_OF_RESIDENCE]: {
    ...onboardingRouteProps,
    accessRoute: EAccessRoute.CONNECTED,
    translation: Translate.page.RESIDENCE,
  },
  [ERouting.KYC_EXPOSED_PERSON]: {
    ...onboardingRouteProps,
    accessRoute: EAccessRoute.CONNECTED,
    translation: Translate.page.KYC_EXPOSED_PERSON,
  },
  [ERouting.KYC_OCCUPATION]: {
    ...onboardingRouteProps,
    accessRoute: EAccessRoute.CONNECTED,
    translation: Translate.page.KYC_OCCUPATION,
  },
  [ERouting.KYC_BUSINESS_SECTOR]: {
    ...onboardingRouteProps,
    accessRoute: EAccessRoute.CONNECTED,
    translation: Translate.page.KYC_BUSINESS_SECTOR,
  },
  [ERouting.KYC_ANNUAL_INCOME]: {
    ...onboardingRouteProps,
    accessRoute: EAccessRoute.CONNECTED,
    translation: Translate.page.KYC_ANNUAL_INCOME,
  },
  [ERouting.KYC_FORECASTED_ANNUAL_INVESTMENTS]: {
    ...onboardingRouteProps,
    accessRoute: EAccessRoute.CONNECTED,
    translation: Translate.page.KYC_FORECASTED_ANNUAL_INVESTMENTS,
  },
  [ERouting.KYC_SOURCES_OF_FUNDS]: {
    ...onboardingRouteProps,
    accessRoute: EAccessRoute.CONNECTED,
    translation: Translate.page.KYC_SOURCES_OF_FUNDS,
  },
  [ERouting.KYC_LOCATION]: {
    ...onboardingRouteProps,
    accessRoute: EAccessRoute.CONNECTED,
    translation: Translate.page.KYC_LOCATION,
  },
  [ERouting.CHECKOUT_SHIPPING]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    sitemap: DEFAULT_SITEMAP_PROPERTIES,
    header: {
      type: ELayout.SHORT,
      hasSelectCurrency: false,
      isLight: false,
      hasSelectLanguage: true,
    },
    noFooter: { isHidden: true },
    isDarkBackground: { isMobile: true, isTablet: true, isDesktop: true },
    translation: Translate.page.CHECKOUT_SHIPPING,
  },
  [ERouting.CHECKOUT_BILLING]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    sitemap: DEFAULT_SITEMAP_PROPERTIES,
    header: {
      type: ELayout.SHORT,
      hasSelectCurrency: false,
      isLight: false,
      hasSelectLanguage: true,
    },
    noFooter: { isHidden: true },
    isDarkBackground: { isMobile: true, isTablet: true, isDesktop: true },
    translation: Translate.page.CHECKOUT_BILLING,
  },
  [ERouting.CHECKOUT_PAYMENT]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    sitemap: DEFAULT_SITEMAP_PROPERTIES,
    header: {
      type: ELayout.SHORT,
      hasSelectCurrency: false,
      isLight: false,
      hasSelectLanguage: true,
    },
    noFooter: { isHidden: true },
    isDarkBackground: { isMobile: true, isTablet: true, isDesktop: true },
    translation: Translate.page.CHECKOUT_PAYMENT,
  },
  [ERouting.CHECKOUT_REVIEW]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    sitemap: DEFAULT_SITEMAP_PROPERTIES,
    header: {
      type: ELayout.SHORT,
      hasSelectCurrency: false,
      isLight: false,
      hasSelectLanguage: true,
    },
    noFooter: { isHidden: true },
    isDarkBackground: { isMobile: true, isTablet: true, isDesktop: true },
    translation: Translate.page.CHECKOUT_REVIEW,
  },
  [ERouting.IMPRESSUM]: {
    ...defaultHeader,
    breadcrumb: [ERouting.HOME],
    translation: Translate.page.IMPRESSUM,
  },
  [ERouting.PAGES]: {
    ...defaultHeader,
    breadcrumb: [],
    keyRouteDynamic: ERoutingDynamic.PAGES,
    keyRouteParent: ERouting.HOME,
    translation: Translate.page.PAGES,
    hasCustomSitemap: true,
  },
  [ERouting.PASSWORD_SETTINGS]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    isDarkBackground: { isTablet: true, isDesktop: true },
    breadcrumb: [ERouting.HOME, ERouting.PROFILE],
    translation: Translate.page.PASSWORD_SETTINGS,
  },
  [ERouting.PURCHASING_LIMIT]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME],
    translation: Translate.page.PURCHASING_LIMIT,
  },
  [ERouting.REFERRALS_TERMS]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.PROFILE, ERouting.REFERRALS],
    translation: Translate.page.REFERRALS_TERMS,
  },
  [ERouting.REFERRALS]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.PROFILE],
    isDarkBackground: { isTablet: true, isDesktop: true },
    translation: Translate.page.REFERRALS,
  },
  [ERouting.REFERRAL]: {
    ...defaultHeader,
    sitemap: null,
    translation: Translate.page.HOME,
  },
  [ERouting.WISHLIST]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.PROFILE],
    translation: Translate.page.WISHLIST,
  },
  [ERouting.EMAIL_UNSUBSCRIBE]: {
    ...defaultHeader,
    sitemap: null,
    translation: Translate.page.EMAIL_UNSUBSCRIBE,
  },
  [ERouting.PRICING]: {
    ...defaultHeader,
    breadcrumb: [ERouting.HOME],
    header: {
      type: ELayout.DEFAULT,
      isLight: false,
      shouldShowBanner: true,
    },
    translation: Translate.page.PRICING,
    isFullWidthPage: true,
  },
  [ERouting.SIGN_IN]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.NOT_CONNECTED,
    header: {
      type: ELayout.SHORT,
      hasSelectLanguage: true,
      isLight: false,
    },
    isDarkBackground: { isTablet: true, isDesktop: true },
    isFullHeightPage: true,
    translation: Translate.page.SIGN_IN,
    noIndex: true,
  },
  [ERouting.PROFILE]: {
    ...defaultHeader,
    breadcrumb: [ERouting.HOME],
    isDarkBackground: { isTablet: true, isDesktop: true },
    accessRoute: EAccessRoute.CONNECTED,
    translation: Translate.page.PAGE_PROFILE,
  },
  [ERouting.PAYMENTS]: {
    ...defaultHeader,
    breadcrumb: [ERouting.HOME, ERouting.PROFILE],
    isDarkBackground: { isTablet: true, isDesktop: true },
    accessRoute: EAccessRoute.CONNECTED,
    translation: Translate.page.PAYMENTS,
  },
  [ERouting.VERIFY_AUTH]: {
    ...defaultHeader,
    breadcrumb: [ERouting.HOME, ERouting.SIGN_IN],
    accessRoute: EAccessRoute.NOT_CONNECTED,
    translation: Translate.page.VERIFY_AUTH,
  },
  [ERouting.TWO_STEPS_AUTH_SMS]: {
    ...defaultHeader,
    breadcrumb: [ERouting.HOME, ERouting.PROFILE],
    accessRoute: EAccessRoute.CONNECTED,
    translation: Translate.page.TWO_STEPS_AUTH_SMS,
  },
  [ERouting.TWO_STEPS_AUTH_TOTP]: {
    ...defaultHeader,
    breadcrumb: [ERouting.HOME, ERouting.PROFILE],
    accessRoute: EAccessRoute.CONNECTED,
    translation: Translate.page.TWO_STEPS_AUTH_TOTP,
  },
  [ERouting.EMERGENCY_PEOPLE]: {
    ...defaultHeader,
    breadcrumb: [ERouting.HOME, ERouting.PROFILE],
    isDarkBackground: { isTablet: true, isDesktop: true },
    accessRoute: EAccessRoute.CONNECTED,
    translation: Translate.page.EMERGENCY_PEOPLE,
  },
  [ERouting.ALERT_SETTINGS]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.PROFILE],
    translation: Translate.page.ALERT_SETTINGS,
  },
  [ERouting.DELIVERY_CLAIMS_BREAKDOWN]: {
    ...defaultHeader,
    header: {
      type: ELayout.SHORT,
      isLight: false,
      hasSelectLanguage: true,
    },
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.WALLET],
    translation: Translate.page.DELIVERY_CLAIMS_BREAKDOWN,
  },
  [ERouting.DELIVERY_CLAIMS_DELIVERY]: {
    ...defaultHeader,
    header: {
      type: ELayout.SHORT,
      isLight: false,
      hasSelectLanguage: true,
    },
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.WALLET],
    translation: Translate.page.DELIVERY_CLAIMS_DELIVERY,
  },
  [ERouting.DELIVERY_CLAIMS_BILLING_ADDRESS]: {
    ...defaultHeader,
    header: {
      type: ELayout.SHORT,
      isLight: false,
      hasSelectLanguage: true,
    },
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.WALLET],
    translation: Translate.page.DELIVERY_CLAIMS_BILLING_ADDRESS,
  },
  [ERouting.DELIVERY_CLAIMS_PAYMENT_METHOD]: {
    ...defaultHeader,
    header: {
      type: ELayout.SHORT,
      isLight: false,
      hasSelectLanguage: true,
    },
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.WALLET],
    translation: Translate.page.DELIVERY_CLAIMS_PAYMENT_METHOD,
  },
  [ERouting.DELIVERY_CLAIMS_ORDER_REVIEW]: {
    ...defaultHeader,
    header: {
      type: ELayout.SHORT,
      isLight: false,
      hasSelectLanguage: true,
    },
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.WALLET],
    translation: Translate.page.DELIVERY_CLAIMS_ORDER_REVIEW,
  },
  [ERouting.DELIVERY_CLAIMS_THANK_YOU]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.WALLET],
    translation: Translate.page.DELIVERY_CLAIMS_THANK_YOU,
  },
  [ERouting.CHECKOUT_THANKS]: {
    ...defaultHeader,
    header: {
      type: ELayout.DEFAULT,
      isLight: false,
      shouldShowBanner: true,
    },
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME],
    isDarkBackground: { isMobile: true, isTablet: true, isDesktop: true },
    translation: Translate.page.CHECKOUT_THANK_YOU,
  },
  [ERouting.GIFTS]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.WALLET],
    translation: Translate.page.GIFT_LIST,
  },
  [ERouting.GIFT_RECIPIENT]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.WALLET],
    translation: Translate.page.GIFT_RECIPIENT,
  },
  [ERouting.GIFT_SUBMIT]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.WALLET],
    translation: Translate.page.GIFT_SUBMIT,
  },
  [ERouting.GIFT_CONFIRMATION]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.WALLET],
    translation: Translate.page.GIFT_CONFIRMATION,
  },
  [ERouting.GIFT_PENDING]: {
    ...defaultHeader,
    sitemap: null,
    accessRoute: EAccessRoute.DEFAULT,
    breadcrumb: [ERouting.HOME],
    translation: Translate.page.GIFT_PENDING,
  },
  [ERouting.DETAILS_GIFT]: {
    ...defaultHeader,
    sitemap: null,
    breadcrumb: [ERouting.HOME, ERouting.WALLET],
    breadcrumbWithLogin: [ERouting.WALLET],
    header: {
      type: ELayout.DEFAULT,
      isLight: false,
      shouldShowGiftPurchasingBanner: true,
    },
    translation: Translate.page.GIFT_DETAIL,
  },
  [ERouting.CART]: {
    ...defaultHeader,
    sitemap: null,
    breadcrumb: [],
    translation: Translate.page.CART,
  },
  [ERouting.ABANDONED_CART]: {
    ...defaultHeader,
    sitemap: null,
    accessRoute: EAccessRoute.DEFAULT,
    breadcrumb: [],
    translation: Translate.page.ABANDONED_CART,
  },
  [ERouting.ACCOUNT_TRANSACTIONS]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.PROFILE, ERouting.PAYMENTS],
    isDarkBackground: { isTablet: true, isDesktop: true },
    translation: Translate.page.ACCOUNT_TRANSACTIONS,
  },
  [ERouting.BLOG_HOME]: {
    ...defaultHeader,
    breadcrumb: [],
    header: {
      hasSelectLanguage: true,
      type: ELayout.BLOG,
      isLight: true,
    },
    isFullHeightPage: true,
    translation: Translate.page.BLOG_HOME,
  },
  [ERouting.BLOG_ARTICLE]: {
    ...defaultHeader,
    breadcrumb: [ERouting.BLOG_HOME],
    keyRouteDynamic: ERoutingDynamic.BLOG_ARTICLE,
    keyRouteParent: ERouting.BLOG_HOME,
    header: {
      hasSelectLanguage: true,
      type: ELayout.BLOG,
      isLight: true,
    },
    isFullHeightPage: true,
    translation: Translate.page.BLOG_ARTICLE,
    hasCustomSitemap: true,
  },
  [ERouting.BLOG_CATEGORY]: {
    ...defaultHeader,
    breadcrumb: [ERouting.BLOG_HOME],
    keyRouteDynamic: ERoutingDynamic.BLOG_CATEGORY,
    keyRouteParent: ERouting.BLOG_HOME,
    header: {
      hasSelectLanguage: true,
      type: ELayout.BLOG,
      isLight: true,
    },
    isFullHeightPage: true,
    translation: Translate.page.BLOG_HOME,
    hasCustomSitemap: true,
  },
  [ERouting.BLOG_TAG]: {
    ...defaultHeader,
    breadcrumb: [ERouting.BLOG_HOME],
    keyRouteDynamic: ERoutingDynamic.BLOG_TAG,
    keyRouteParent: ERouting.BLOG_HOME,
    header: {
      hasSelectLanguage: true,
      type: ELayout.BLOG,
      isLight: true,
    },
    isFullHeightPage: true,
    translation: Translate.page.BLOG_HOME,
    hasCustomSitemap: true,
  },
  [ERouting.BLOG_SEARCH]: {
    ...defaultHeader,
    breadcrumb: [],
    header: {
      hasSelectLanguage: true,
      type: ELayout.BLOG,
      isLight: true,
    },
    translation: Translate.page.BLOG_HOME,
    isFullHeightPage: true,
  },
  [ERouting.HOME]: {
    ...defaultHeader,
    header: {
      type: ELayout.DEFAULT,
      isLight: false,
      shouldShowBanner: true,
    },
    translation: Translate.page.HOME,
    isFullWidthPage: true,
  },
  [ERouting.CHANGE_PASSWORD]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.NOT_CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.SIGN_IN],
    header: {
      type: ELayout.SHORT,
      isLight: false,
      hasSelectLanguage: true,
    },
    isFullHeightPage: true,
    translation: Translate.page.CHANGE_PASSWORD,
  },
  [ERouting.FORGOTTEN_PASSWORD]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.NOT_CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.SIGN_IN],
    header: {
      type: ELayout.SHORT,
      hasSelectLanguage: true,
      isLight: false,
    },
    isFullHeightPage: true,
    translation: Translate.page.FORGOTTEN_PASSWORD,
  },
  [ERouting.UNLOCK_ACCOUNT]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.NOT_CONNECTED,
    sitemap: null,
    breadcrumb: [ERouting.HOME, ERouting.SIGN_IN],
    translation: Translate.page.UNLOCK_ACCOUNT,
  },
  [ERouting.SHIPPING_ADDRESS]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    isDarkBackground: { isTablet: true, isDesktop: true },
    breadcrumb: [ERouting.HOME, ERouting.PROFILE],
    translation: Translate.page.SHIPPING_ADDRESS,
  },
  [ERouting.BILLING_ADDRESS]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    isDarkBackground: { isDesktop: true, isMobile: true },
    breadcrumb: [ERouting.HOME, ERouting.PROFILE],
    translation: Translate.page.BILLING_ADDRESS,
  },
  [ERouting.RESEND_INSTRUCTION]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.NOT_CONNECTED,
    sitemap: null,
    breadcrumb: [ERouting.HOME, ERouting.SIGN_IN],
    header: {
      type: ELayout.SHORT,
      isLight: true,
    },
    isFullHeightPage: true,
    translation: Translate.page.RESEND_INSTRUCTIONS,
  },
  [ERouting.SEARCH]: {
    ...defaultHeader,
    breadcrumb: [],
    header: {
      type: ELayout.DEFAULT,
      isLight: false,
      shouldShowBanner: true,
    },
    translation: Translate.page.SEARCH,
  },
  [ERouting.BUY]: {
    ...defaultHeader,
    header: {
      type: ELayout.DEFAULT,
      isLight: false,
      shouldShowBanner: true,
    },
    accessRoute: EAccessRoute.DEFAULT,
    keyRouteDynamic: ERoutingDynamic.METAL,
    keyRouteParent: ERouting.HOME_BUY,
    breadcrumb: null,
    translation: Translate.page.SHOP,
    hasCustomSitemap: true,
    isDarkBackground: { isPhone: true, isTablet: true, isMobile: true, isDesktop: true },
  },
  [ERouting.HOME_BUY]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.DEFAULT,
    breadcrumb: null,
    translation: Translate.page.HOME_SHOP,
    isDarkBackground: { isPhone: true, isTablet: true, isMobile: true, isDesktop: true },
  },
  [ERouting.EXAMPLE]: {
    ...defaultHeader,
    sitemap: null,
    translation: Translate.page.EXAMPLE,
  },
  [ERouting.PRODUCT_SHOW]: {
    ...defaultHeader,
    header: {
      type: ELayout.DEFAULT,
      isLight: false,
      shouldShowBanner: true,
    },
    breadcrumb: [],
    keyRouteDynamic: ERoutingDynamic.PRODUCT_SHOW,
    keyRouteParent: ERouting.HOME_BUY,
    translation: Translate.page.PRODUCT_SHOW,
    hasCustomSitemap: true,
  },
  [ERouting.SHOP_SUBCATEGORY]: {
    ...defaultHeader,
    header: {
      type: ELayout.DEFAULT,
      isLight: false,
      shouldShowBanner: true,
    },
    breadcrumb: [],
    keyRouteDynamic: ERoutingDynamic.METAL_CATEGORY,
    keyRouteParent: ERouting.HOME_BUY,
    translation: Translate.page.SHOP_SUBCATEGORY,
    hasCustomSitemap: true,
    isDarkBackground: { isPhone: true, isTablet: true, isMobile: true, isDesktop: true },
  },
  [ERouting.PRECIOUS_METAL_GUIDE]: {
    ...defaultHeader,
    breadcrumb: [ERouting.HOME],
    keyRouteDynamic: ERoutingDynamic.PRECIOUS_METAL_GUIDE,
    keyRouteParent: ERouting.HOME,
    translation: Translate.page.PRECIOUS_METAL_GUIDE,
  },
  [ERouting.PRECIOUS_METAL_GUIDE_LEVEL1]: {
    ...defaultHeader,
    breadcrumb: [ERouting.HOME],
    keyRouteDynamic: ERoutingDynamic.PRECIOUS_METAL_GUIDE_LEVEL1,
    keyRouteParent: ERouting.PRECIOUS_METAL_GUIDE,
    translation: Translate.page.PRECIOUS_METAL_GUIDE,
    hasCustomSitemap: true,
  },
  [ERouting.PRECIOUS_METAL_GUIDE_LEVEL2]: {
    ...defaultHeader,
    breadcrumb: [ERouting.HOME],
    keyRouteDynamic: ERoutingDynamic.PRECIOUS_METAL_GUIDE_LEVEL2,
    keyRouteParent: ERouting.PRECIOUS_METAL_GUIDE,
    translation: Translate.page.PRECIOUS_METAL_GUIDE,
    hasCustomSitemap: true,
  },
  [ERouting.PRECIOUS_METAL_GUIDE_LEVEL3]: {
    ...defaultHeader,
    breadcrumb: [ERouting.HOME],
    keyRouteDynamic: ERoutingDynamic.PRECIOUS_METAL_GUIDE_LEVEL3,
    keyRouteParent: ERouting.PRECIOUS_METAL_GUIDE,
    translation: Translate.page.PRECIOUS_METAL_GUIDE,
    hasCustomSitemap: true,
  },
  [ERouting.DEPOSIT]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.PROFILE, ERouting.PAYMENTS],
    translation: Translate.page.DEPOSIT,
  },
  [ERouting.TUTORIAL_VIDEOS]: {
    ...defaultHeader,
    breadcrumb: [ERouting.HOME],
    translation: Translate.page.TUTORIAL_VIDEOS,
  },
  [ERouting.WITHDRAWAL]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.PROFILE, ERouting.PAYMENTS],
    translation: Translate.page.WITHDRAWAL,
  },
  [ERouting.TUTORIAL_VIDEO]: {
    ...defaultHeader,
    breadcrumb: [ERouting.HOME, ERouting.TUTORIAL_VIDEOS],
    keyRouteDynamic: ERoutingDynamic.TUTORIAL_VIDEO,
    keyRouteParent: ERouting.TUTORIAL_VIDEOS,
    translation: Translate.page.TUTORIAL_VIDEO,
    hasCustomSitemap: true,
  },
  [ERouting.SILVER_LANDING]: {
    ...defaultHeader,
    header: {
      type: ELayout.LANDING,
      isLight: false,
    },
    translation: Translate.page.SILVER_LANDING,
    isFullWidthPage: true,
  },
  [ERouting.STORAGE_SOLUTION]: {
    ...defaultHeader,
    breadcrumb: [ERouting.HOME],
    header: {
      type: ELayout.DEFAULT,
      isLight: false,
      shouldShowBanner: true,
    },
    translation: Translate.page.STORAGE_SOLUTION,
  },
  [ERouting.STORAGE_FEES]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME],
    header: {
      type: ELayout.DEFAULT,
      isLight: false,
      shouldShowBanner: false,
    },
    translation: Translate.page.STORAGE_FEES,
  },
  [ERouting.SAVING_ASSISTANT]: {
    ...defaultHeader,
    breadcrumb: [],
    translation: Translate.page.SAVING_ASSISTANT,
  },
  [ERouting.WALLET]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    header: {
      type: ELayout.DEFAULT,
      isLight: false,
      shouldShowBanner: true,
    },
    noPaddingMargin: true,
    isDarkBackground: { isDesktop: true, isMobile: false },
    translation: Translate.page.WALLET_PAGE,
  },
  [ERouting.WALLET_AUTO_SAVINGS]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.WALLET],
    isDarkBackground: { isTablet: true, isDesktop: true },
    translation: Translate.page.WALLET_AUTO_SAVINGS,
  },
  [ERouting.WALLET_AUTO_SAVINGS_SHOW]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.HOME, ERouting.WALLET, ERouting.WALLET_AUTO_SAVINGS],
    isDarkBackground: { isTablet: true, isDesktop: true },
    translation: Translate.page.WALLET_AUTO_SAVINGS_SHOW,
  },
  [ERouting.WALLET_ORDERS]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.WALLET],
    isDarkBackground: { isPhone: true, isMobile: true, isTablet: true, isDesktop: true },
    translation: Translate.page.WALLET_ORDERS,
  },
  [ERouting.WALLET_ORDER_SHOW]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.WALLET, ERouting.WALLET_ORDERS],
    isDarkBackground: { isMobile: true, isTablet: true, isDesktop: true },
    translation: Translate.page.WALLET_ORDER_SHOW,
  },
  [ERouting.WALLET_GIFTS]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.WALLET],
    isDarkBackground: { isPhone: true, isMobile: true, isTablet: true, isDesktop: true },
    translation: Translate.page.WALLET_GIFTS,
  },
  [ERouting.WALLET_GIFT_SHOW]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.WALLET, ERouting.WALLET_GIFTS],
    isDarkBackground: { isMobile: true, isTablet: true, isDesktop: true },
    translation: Translate.page.WALLET_GIFT_SHOW,
  },
  [ERouting.WALLET_CLAIMS]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.WALLET],
    isDarkBackground: { isPhone: true, isMobile: true, isTablet: true, isDesktop: true },
    translation: Translate.page.WALLET_CLAIMS,
  },
  [ERouting.WALLET_CLAIM_SHOW]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.WALLET, ERouting.WALLET_CLAIMS],
    isDarkBackground: { isMobile: true, isTablet: true, isDesktop: true },
    translation: Translate.page.WALLET_CLAIM_SHOW,
  },
  [ERouting.WALLET_SELL]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.WALLET],
    header: {
      type: ELayout.SHORT,
      hasSelectCurrency: false,
      isLight: false,
      hasSelectLanguage: true,
    },
    translation: Translate.page.WALLET_SELL,
  },
  [ERouting.WALLET_SELL_REVIEW]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.WALLET],
    header: {
      type: ELayout.SHORT,
      hasSelectCurrency: false,
      isLight: false,
      hasSelectLanguage: true,
    },
    translation: Translate.page.WALLET_SELL_REVIEW,
  },
  [ERouting.WALLET_SELL_THANKS]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.CONNECTED,
    breadcrumb: [ERouting.WALLET],
    header: {
      type: ELayout.SHORT,
      hasSelectCurrency: false,
      isLight: false,
      hasSelectLanguage: true,
    },
    translation: Translate.page.WALLET_SELL_THANKS,
  },
  [ERouting.FAQ]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.DEFAULT,
    sitemap: DEFAULT_SITEMAP_PROPERTIES,
    breadcrumb: [],
    translation: Translate.page.FAQ_LIST,
  },
  [ERouting.FAQ_SHOW]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.DEFAULT,
    sitemap: DEFAULT_SITEMAP_PROPERTIES,
    keyRouteDynamic: ERoutingDynamic.FAQ_ARTICLE,
    keyRouteParent: ERouting.FAQ,
    breadcrumb: [ERouting.FAQ],
    translation: Translate.page.FAQ_SHOW,
    hasCustomSitemap: true,
  },
  [ERouting.FAQ_SEARCH]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.DEFAULT,
    sitemap: DEFAULT_SITEMAP_PROPERTIES,
    breadcrumb: [],
    translation: Translate.page.FAQ_SEARCH,
  },
  [ERouting.CONTACT]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.DEFAULT,
    sitemap: DEFAULT_SITEMAP_PROPERTIES,
    breadcrumb: [],
    translation: Translate.page.CONTACT,
  },
  [ERouting.CONTACT_FORM]: {
    ...defaultHeader,
    accessRoute: EAccessRoute.DEFAULT,
    sitemap: DEFAULT_SITEMAP_PROPERTIES,
    breadcrumb: [ERouting.CONTACT],
    translation: Translate.page.CONTACT_FORM,
  },
  [ERouting.MAINTENANCE]: {
    ...defaultHeader,
    noFooter: { isHidden: true },
    noHeader: { isHidden: true },
    noPaddingMargin: true,
    isFullHeightPage: true,
    sitemap: null,
    translation: Translate.page.MAINTENANCE,
  },
  [ERouting.NOT_FOUND]: {
    ...defaultHeader,
    sitemap: null,
    translation: Translate.page.NOT_FOUND,
  },
  [ERouting.INTERNAL_ERROR]: {
    ...defaultHeader,
    sitemap: null,
    translation: Translate.page.INTERNAL_ERROR,
  },
  [ERouting.BAD_REQUEST]: {
    ...defaultHeader,
    sitemap: null,
    translation: Translate.page.BAD_REQUEST,
  },
  [ERouting.SAFERPAY_REDIRECTION]: {
    ...defaultHeader,
    sitemap: null,
    translation: Translate.page.CHECKOUT,
  },
  [ERouting.SAFERPAY_TRANSACTION]: {
    ...defaultHeader,
    sitemap: null,
    translation: Translate.page.CHECKOUT,
  },
};
