import dynamic from 'next/dynamic';
import { memo } from 'react';

import { Box, ButtonBackTop, Container } from '@core/component';
import { EActionAttribute, EColor } from '@core/type';

import { FooterBottom } from './FooterBottom';
import { FooterMenu } from './FooterMenu';
import { FooterShort } from './FooterShort';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const FooterSubscribeLazy = dynamic(() =>
  // eslint-disable-next-line @typescript-eslint/naming-convention
  import('./FooterSubscribe').then(({ FooterSubscribe }) => FooterSubscribe),
);

export const FooterContainer = ({
  isShort,
  haveSubscribe,
  shouldShowCurrencyLink,
}: {
  isShort?: boolean;
  haveSubscribe?: boolean;
  shouldShowCurrencyLink?: boolean;
}) => {
  return (
    <Box as={EActionAttribute.FOOTER}>
      <Box
        backgroundColor={{ commonType: EColor.BLUE, intensity: EColor.R600 }}
        position={'relative'}
      >
        <Container>
          {!isShort && <ButtonBackTop />}
          {haveSubscribe && <FooterSubscribeLazy />}
        </Container>
      </Box>
      <Box
        backgroundColor={{ semanticType: EColor.PRIMARY, variant: EColor.LIGHT }}
        transition={'transform .7s;'}
      >
        <Container position={'relative'}>
          {!isShort && <FooterMenu shouldShowCurrencyLink={shouldShowCurrencyLink} />}
        </Container>
      </Box>
      <Box backgroundColor={{ semanticType: EColor.PRIMARY, variant: EColor.DARK }}>
        <Container>{isShort ? <FooterShort /> : <FooterBottom />}</Container>
      </Box>
    </Box>
  );
};

export const Footer = memo(FooterContainer);
