import { memo } from 'react';
import styled, { css } from 'styled-components';

import { zIndexes } from '@core/constant';
import { EColor, EFontWeight, EIcon, EPosition, ESize, EZIndexName } from '@core/type';

import { Icon } from '../icon';
import { Box } from '../layout';
import { Typography } from '../typography';
import { placements, transforms } from './Drawer';
import { DrawerPosition, SubDrawerProps } from './interface-drawer';

export const DrawerSub = memo(
  ({
    children,
    position,
    backgroundColor,
    size,
    listTitle,
    title,
    drawerLevel,
    drawerLevelProps,
    handleDrawerLevelProps,
  }: SubDrawerProps) => {
    const isRightMenu = position === EPosition.RIGHT;

    return (
      <>
        <Box
          role={'button'}
          cursor={'pointer'}
          onClick={() => handleDrawerLevelProps({ [drawerLevel]: true })}
          display={'flex'}
          gap={'16px'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          {listTitle || <Typography>{title}</Typography>}
          <Icon
            type={EIcon.ANGLE}
            orientation={EPosition.RIGHT}
            size={ESize.MD}
            flexShrink={'0'}
            margin={'-1px 0'}
          />
        </Box>

        <DrawerContent
          isOpen={drawerLevelProps[drawerLevel]}
          size={size}
          position={position}
          backgroundColor={backgroundColor}
        >
          <Box
            marginBottom={'24px'}
            padding="32px 20px 32px 40px"
            backgroundColor={EColor.PRIMARY}
            lineHeight="24px"
            height="88px"
            onClick={() => handleDrawerLevelProps({ [drawerLevel]: false })}
          >
            <Box
              display={'flex'}
              gap={'16px'}
              flexDirection={isRightMenu ? 'row-reverse' : 'row'}
              justifyContent={isRightMenu ? 'space-between' : 'flex-start'}
            >
              <Icon
                type={EIcon.ANGLE}
                orientation={position}
                color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
                size={ESize.MD}
                strokeWidth={2}
              ></Icon>
              <Typography
                color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
                fontWeight={EFontWeight.BOLD}
              >
                {title}
              </Typography>
            </Box>
          </Box>
          {children}
        </DrawerContent>
      </>
    );
  },
);
DrawerSub.displayName = 'DrawerSub';

const DrawerContent = styled.nav<{
  isOpen: boolean;
  position: DrawerPosition;
  size?: string;
  backgroundColor?: string;
}>(
  ({
    isOpen,
    position,
    size,
    backgroundColor,
  }: {
    isOpen: boolean;
    position: DrawerPosition;
    size?: string;
    backgroundColor?: string;
  }) => {
    return css`
      display: block;
      box-sizing: border-box;
      position: fixed;
      ${placements[position]}
      z-index: ${zIndexes[EZIndexName.TOOLTIP]};
      width: ${position !== EPosition.TOP && position !== EPosition.BOTTOM && size
        ? size
        : '300px'};
      transform: ${!isOpen ? transforms[position] : 'translateX(0)'};
      transition: transform 0.2s;
      overflow: auto;
      background-color: ${backgroundColor || '#ffffff'};
      ${position === EPosition.LEFT && 'box-shadow: -10px 0px 10px rgba(0, 0, 0, 0.19);'}
      flex-direction: column;
      backface-visibility: hidden;
      margin: 0;
      padding-left: 0;
      padding-bottom: 24px;
      list-style-type: none;
      height: 100vh;
    `;
  },
);
