import type { ThemePalette } from '@core/type';
import { EColor, EColorName } from '@core/type';

import { themeDefaultPalette } from './theme-default-palette';

export const themeDarkPalette: ThemePalette = {
  ...themeDefaultPalette,
  background: {
    ...themeDefaultPalette.background,
    [EColor.SECONDARY]: {
      ...themeDefaultPalette.background[EColor.SECONDARY],
      [EColor.LIGHT]: EColorName.ATHENS_GRAY,
    },
    [EColor.ACCENT]: {
      ...themeDefaultPalette.background[EColor.ACCENT],
      [EColor.MAIN]: EColorName.DODGER_BLUE,
    },
  },
};
