import { useEffect, useState } from 'react';

import { Banner as BannerComponent } from '@core/component';
import { useContextUtil } from '@core/context';
import { useQueryApi } from '@core/hook';
import { EStorageKey, getItem, setItem } from '@core/storage';
import { EColor, EStorageType } from '@core/type';
import { BannerEntity, EQueryKey } from '@core/type/api';

import { BannerColor, BannerData } from './interface-banner';

const BANNER_CLOSING_TIMEOUT = 300;

export const ConnectedBanner = () => {
  const [bannerData, setBannerData] = useState<BannerData>(null);
  const [storedToken, setStoredToken] = useState<string>(null);
  const { isBotDetected } = useContextUtil();

  useEffect(() => {
    const storedBannerToken = getItem(EStorageType.COOKIE, EStorageKey.BANNER_COOKIE_NAME);
    if (storedBannerToken) {
      setStoredToken(storedBannerToken);
    }
  }, []);

  const { isSuccess, data } = useQueryApi<BannerEntity>(EQueryKey.BANNER, null, {
    enabled: !isBotDetected,
  });

  useEffect(() => {
    if (isSuccess && data?.token && data?.token !== storedToken) {
      const { color, ...restData } = data;

      let bannerColor: BannerColor = EColor.SUCCESS;
      if (color === 'blue') {
        bannerColor = EColor.INFO;
      } else if (color === 'red') {
        bannerColor = EColor.DANGER;
      }

      const bannerData: BannerData = {
        color: bannerColor,
        ...restData,
      };

      setBannerData(bannerData);
    }
  }, [data, isSuccess]);

  const [isOpen, setIsOpen] = useState(true);

  if (!bannerData) {
    return null;
  }

  return (
    <BannerComponent
      marginTop={'16px'}
      color={bannerData.color}
      onClose={() => {
        setItem(EStorageType.COOKIE, EStorageKey.BANNER_COOKIE_NAME, bannerData.token);
        setIsOpen(false);
        setTimeout(() => {
          setBannerData(null);
        }, BANNER_CLOSING_TIMEOUT);
      }}
      title={bannerData.title}
      text={bannerData.subtitle}
      isOpen={isOpen}
    />
  );
};
