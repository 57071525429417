import Image from 'next/image';

import IconAlertFilledSvg from './svgs/icon-alert-filled.svg';

export const IconAlertFilled = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconAlertFilledSvg}
    alt="Alert icon"
    height={24}
    width={24}
  />
);
