import { ESize, ThemeContainerMediaQueries, ThemeMediaQuery } from '@core/type';

export const themeMediaQueries: ThemeMediaQuery = {
  [ESize.XS]: 0,
  [ESize.SM]: 576,
  [ESize.MD]: 768,
  [ESize.LG]: 992,
  [ESize.XL]: 1200,
};

export const themeContainerMaxWidth: ThemeContainerMediaQueries = {
  [ESize.XS]: '100%',
  [ESize.SM]: 750,
  [ESize.MD]: 750,
  [ESize.LG]: 980,
  [ESize.XL]: 1170,
};
